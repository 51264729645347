<template>
    <template v-if="showInCurrentLocale">
        <slot />
    </template>
</template>
<script setup>
import { useNuxtApp } from '#imports';
import { computed } from 'vue';

const { $i18n } = useNuxtApp();
const props = defineProps({
    exclude: {
        type: Boolean,
        default: false,
        required: false,
    },
    fifi: {
        type: Boolean,
        default: false,
        required: false,
    },
    svfi: {
        type: Boolean,
        default: false,
        required: false,
    },
    svse: {
        type: Boolean,
        default: false,
        required: false,
    },
    eneu: {
        type: Boolean,
        default: false,
        required: false,
    },
    nono: {
        type: Boolean,
        default: false,
        required: false,
    },
});

const showInCurrentLocale = computed(() => {
    const { exclude, fifi, svfi, svse, eneu, nono } = props;
    const toExcludeOrInclude = locale => !exclude ? locale : (exclude && !locale);

    return [
        ...(toExcludeOrInclude(eneu) ? [ 'en-EU' ] : []),
        ...(toExcludeOrInclude(fifi) ? [ 'fi-FI' ] : []),
        ...(toExcludeOrInclude(nono) ? [ 'no-NO' ] : []),
        ...(toExcludeOrInclude(svse) ? [ 'sv-SE' ] : []),
        ...(toExcludeOrInclude(svfi) ? [ 'sv-FI' ] : []),
    ].includes($i18n.locale.value);
});

</script>
