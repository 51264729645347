<template>
    <div class="quick-navigation">
        <Locale svse fifi>
            <SFChat />
        </Locale>
        <Locale exclude eneu>
            <nuxt-link
                v-if="!stationLocatorBlockOnPageData"
                :to="$paths.getPath('stationLocator')"
                class="button"
            >
                <span class="button-text">{{ $t('site.quickNav.stationLocator') }} </span>
                <DSFontIcon code="pin" />
            </nuxt-link>
        </Locale>
    </div>
</template>
<script setup>
import { storeToRefs } from 'pinia';
import { usePageStore } from '~dsc/stores/cms/page';
import { computed } from 'vue';

const { pageData } = storeToRefs(usePageStore());
const stationLocatorBlockOnPageData = computed(() => (pageData.value ?? []).some(contentBlock => contentBlock?.type === 'stationlocator'));

</script>
<style lang="scss" scoped>
.quick-navigation {
    position: fixed;
    right: 4rem;
    bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: .5rem;
    z-index: 1;
}

.button {
    display: flex;
    align-items: center;
    min-width: 0;
    padding: 22px;
    height: auto;
    border-radius: 64px;
    background-color: rgba(0, 0, 0, 0.75);
    color: var(--color-base-white, #FFF);
    font-weight: 700;

    .icon {
        display: inline-flex;
        font-size: 18px;
    }
}

.button-text {
    margin-right: 1rem;
}

@include respond-to('at most large mobile') {
    .quick-navigation {
        right: 1rem;
        bottom: 1rem;
    }

    .button-text {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}
</style>
