<template>
    <div class="sf-live-chat">
        <div ref="chatElement"></div>
    </div>
</template>
<script setup>
import { useI18n, useHead } from '#imports';
import { onMounted, useTemplateRef } from 'vue';
import sfChatConfig from '@@/configuration/sf-chat.json';

const { log } = console;

useHead({
    script: [ { src: 'https://service.force.com/embeddedservice/5.0/esw.min.js', type: 'text/javascript' } ],
});

const chatElement = useTemplateRef('chatElement');

const chatEventHandlers = [
    {
        name: 'onHelpButtonClick',
        handler: () => log('onHelpButtonClick event was fired.'),
    },
    {
        name: 'onChatRequestSuccess',
        handler: data => log(`onChatRequestSuccess event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onChatEstablished',
        handler: data => log(`onChatEstablished event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onChasitorMessage',
        handler: data => log(`onChasitorMessage event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onAgentMessage',
        handler: data => log(`onAgentMessage event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onChatConferenceInitiated',
        handler: data => log(`onChatConferenceInitiated event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onAgentJoinedConference',
        handler: data => log(`onAgentJoinedConference event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onAgentLeftConference',
        handler: data => log(`onAgentLeftConference event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onChatConferenceEnded',
        handler: data => log(`onChatConferenceEnded event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onChatTransferSuccessful',
        handler: data => log(`onChatTransferSuccessful event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onChatEndedByChasitor',
        handler: data => log(`onChatEndedByChasitor event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onChatEndedByAgent',
        handler: data => log(`onChatEndedByAgent event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onQueueUpdate',
        handler: data => log(`onQueueUpdate event was fired. liveAgentSessionKey was ${data.liveAgentSessionKey}and queuePosition was ${data.queuePosition}`),
    },
    {
        name: 'onIdleTimeoutOccurred',
        handler: data => log(`onIdleTimeoutOccurred event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onConnectionError',
        handler: data => log(`onConnectionError event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onClickSubmitButton',
        handler: data => log(`onClickSubmitButton event was fired.  liveAgentSessionKey was ${data.liveAgentSessionKey}`),
    },
    {
        name: 'onInviteAccepted',
        handler: () => log('onInviteAccepted event was fired.'),
    },
    {
        name: 'onInviteRejected',
        handler: () => log('onInviteRejected event was fired.'),
    },
    {
        name: 'onInvitationResourceLoaded',
        handler: () => log('onInvitationResourceLoaded event was fired.'),
    },
    {
        name: 'onSettingsCallCompleted',
        handler: data => log(`onSettingsCallCompleted event was fired. Agent availability status is ${data.isAgentAvailable ? 'online' : 'offline'}`),
    },
    {
        name: 'onAvailability',
        handler: data => log(`onAvailability event was fired. Agent availability status is ${data.isAgentAvailable ? 'online' : 'offline'}`),
    },
];

const configIsValid = config => [
    'baseUrl',
    'chatUrl',
    'serviceUrl',
    'token',
    'ChatId',
    'options',
].every(property => Object.hasOwn(config, property));

onMounted(() => {
    if (window.embedded_svc && configIsValid(sfChatConfig)) {
        Object.assign(
            window.embedded_svc.settings,
            chatSettings,
            { targetElement: chatElement.value },
        );
        window.embedded_svc.init(
            sfChatConfig.baseUrl,
            sfChatConfig.chatUrl,
            sfChatConfig.serviceUrl,
            sfChatConfig.token,
            sfChatConfig.ChatId,
            { ...sfChatConfig.options },
        );
        chatEventHandlers.forEach(({ name, handler }) => window.embedded_svc.addEventHandler(name, handler));
    }
});


const { t } = useI18n({
    useScope: 'global',
    messages: {
        'sv-SE': {
            chat: {
                language: 'sv',
                chat: 'Chat',
                chatClosed: 'Chatten stängd',
                loadingText: 'Laddar ...',
                firstname: 'Förnamn',
                lastname: 'Efternamn',
                email: 'E-post',
                subject: 'Subject',
                status: 'Status',
                origin: 'Origin',

            },
        },
        'fi-FI': {
            chat: {
                language: 'fi',
                chat: 'Chat',
                chatClosed: 'Chat suljettu',
                loadingText: 'Lataa ...',
                firstname: 'Etunimi',
                lastname: 'Sukunimi',
                email: 'Sähköposti',
                subject: 'Aihe',
                status: 'Status',
                origin: 'Origin',
            },
        },
    },
});

const chatSettings = {
    displayHelpButton: true,
    language: t('chat.language'),
    defaultMinimizedText: t('chat.chat'),
    disabledMinimizedText: t('chat.chatClosed'),
    loadingText: t('chat.loadingText'),
    offlineSupportMinimizedText: t('chat.chat'),
    prechatBackgroundImgURL: '/images/logo.svg',
    smallCompanyLogoImgURL: '/images/logo.svg',
    enabledFeatures: [ 'LiveAgent' ],
    entryFeature: 'LiveAgent',
    extraPrechatInfo: [
        {
            entityName: 'Contact',
            showOnCreate: true,
            linkToEntityName: 'Case',
            linkToEntityField: 'ContactId',
            saveToTranscript: 'Contact',
            entityFieldMaps: [
                {
                    isExactMatch: true,
                    fieldName: 'FirstName',
                    doCreate: false,
                    doFind: true,
                    label: t('chat.firstname'),
                },
                {
                    isExactMatch: true,
                    fieldName: 'LastName',
                    doCreate: false,
                    doFind: true,
                    label: t('chat.lastname'),
                },
                {
                    isExactMatch: true,
                    fieldName: 'Email',
                    doCreate: false,
                    doFind: true,
                    label: t('chat.email'),
                },
            ],
        },
        {
            entityName: 'Case',
            showOnCreate: true,
            entityFieldMaps: [
                {
                    isExactMatch: false,
                    fieldName: 'Subject',
                    doCreate: true,
                    doFind: false,
                    label: t('chat.subject'),
                },
                {
                    isExactMatch: false,
                    fieldName: 'Status',
                    doCreate: true,
                    doFind: false,
                    label: t('chat.status'),
                },
                {
                    isExactMatch: false,
                    fieldName: 'Origin',
                    doCreate: true,
                    doFind: false,
                    label: t('chat.origin'),
                },
            ],
        },
    ],
    extraPrechatFormDetails: [
        {
            label: t('chat.subject'),
            value: 'Chat SE',
        },
        {
            label: t('chat.email'),
            transcriptFields: [ 'Email__c' ],
        },
        {
            label: t('chat.lastname'),
            transcriptFields: [ 'Last_Name__c' ],
        },
        {
            label: t('chat.firstname'),
            transcriptFields: [ 'First_Name__c' ],
        },
    ],
};

</script>
<style lang="scss" scoped>
.sf-live-chat {
    :deep(.embeddedServiceHelpButton) {
        position: relative;
    }

    :deep(.uiButton.uiButton) {
        min-width: 0;
        padding: 21px;
        height: auto;
        border-radius: 64px;
        background-color: rgba(0, 0, 0, 0.75);
        font-weight: 700;
        font-family: Gotham, serif;
        font-size: 1rem;
    }

    :deep(.helpButton) {
        position: relative;
        height: auto;
        bottom: auto;
        right: auto;
    }

    :deep(.helpButtonEnabled.helpButtonEnabled:hover::before),
    :deep(.helpButtonEnabled.helpButtonEnabled:focus::before) {
        background-color: transparent;
    }

    :deep(.embeddedServiceIcon) {
        margin-right: 0;
        margin-left: 1rem;
        order: 2;
    }
}

@include respond-to('at most large mobile') {
    .sf-live-chat {
        :deep(.helpButtonLabel) {
            visibility: hidden;
            font-size: 0;
        }

        :deep(.embeddedServiceIcon) {
            margin-left: 0;
        }
    }
}
</style>
