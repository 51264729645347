<template>
    <div class="page">
        <main class="page-container">
            <NuxtLoadingIndicator
                :throttle="200"
                :duration="2000"
                :color="colors.primary"
            />
            <DSNotifications />
            <LayoutMainHeader>
                <template #left>
                    <nuxt-link class="logo" :to="$paths.getPath('landingPage')">
                        <object v-bind="logo" />
                    </nuxt-link>
                </template>
                <template #center>
                    <LayoutMainNavigationDesktop
                        class="main-nav"
                        :links="navigationOne"
                    />
                </template>
                <template v-if="$bp.bps.isDesktop" #right>
                    <LayoutSearch />
                    <LayoutQuickLinks :links="layoutData.navigation" />
                    <LayoutUserMenu v-if="isLocal" :customer-type="customerType" />
                </template>
                <template
                    v-if="navigationOne?.find(item => item.url.includes(routeArray.slice(0, 2).join('/')))?.children"
                    #bottom
                >
                    <LayoutMainNavigationDesktop
                        v-if="routeArray.length > 1"
                        class="main-nav"
                        :links="navigationOne?.find(item => item.url.includes(routeArray.slice(0, 2).join('/')))?.children.map(markSelected)"
                    />
                </template>
            </LayoutMainHeader>
            <DSSidebar>
                <template #sidebar-right>
                    <LayoutMainNavigationMobile :links="navigationTree" />
                </template>
            </DSSidebar>
            <NuxtPage
                class="page-content"
                :transition="{
                    onBeforeEnter,
                }"
            />
            <Teleport v-if="modal.name === 'emergencyModal'" :to="modal.modalTarget">
                <div class="ds-modal-content">
                    <div class="head">
                        <div class="ds-title head-content">{{ layoutData.emergencyModal.title }}</div>
                        <button class="close-btn" @click="modal.unsetNameAndClose">
                            <DSIcon
                                code="close"
                                size="xs"
                            />
                        </button>
                    </div>
                    <div class="body">
                        <DSCFText :content="layoutData.emergencyModal.text" class="ds-container text-center" />
                    </div>
                    <div class="foot">
                        <div class="text-center">
                            <button
                                class="ds-btn ds-btn--primary"
                                @click="modal.unsetNameAndClose"
                            >
                                {{ $t('emergencyModal.close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </Teleport>
            <DSModal />
        </main>
        <LayoutFooter
            :icons="layoutData?.icons"
            :items="layoutData?.footerLinks"
            :download="layoutData?.downloadLinks"
            :some="layoutData?.someLinks"
            :extras="layoutData?.extraLinks"
        />
        <QuickNavigation />
    </div>
</template>
<script setup>
import { useLayoutStore } from '~dsc/stores/cms/layout';
import { usePageStore } from '~dsc/stores/cms/page';
import { useCartStore } from '~dsc/stores/cms/cart';
import { ref, computed, onMounted, provide, watch, useNuxtApp, useI18n, useRoute, useAnalytics } from '#imports';
import { storeToRefs } from 'pinia';
import * as websiteTokens from '~dsc/assets/scss/themes/st1-website/_export.module.scss';
import 'lenis/dist/lenis.css';
import { useModalStore } from '~dsc/stores/modal';
import Lenis from 'lenis';
import gtmPerLocale from '@@/configuration/gtm.json';
const { finalizePendingLocaleChange } = useI18n();
const { layoutData, navigationTree } = storeToRefs(useLayoutStore());
const { $paths, $bp, $i18n } = useNuxtApp();
const { pageData } = storeToRefs(usePageStore());
const { initGtm } = useAnalytics();
const route = useRoute();
const modal = useModalStore();

initGtm({ id: gtmPerLocale[$i18n.locale.value] });

const colors = {
    'primary': websiteTokens.default['color-primary-500'],
};

const scrollY = ref(0);
const scrollLimit = ref(150);

const firstBlockFullScreen = computed(() => pageData.value?.[0]?.display?.type === 'fullscreen');
const isLocal = computed(() => $i18n.locale.value !== 'en-EU');
const layout = ref({
    theme: firstBlockFullScreen.value === true ? 'clear' : 'white',
    sidebars: {
        left: false,
        right: false,
    },
    button: {
        ctaIcon: 'arrow-right',
    },
});

const routeArray = computed(() => {
    const routeArray = route.path.split('/');

    return routeArray.filter(value => value !== '' && value !== 'preview');
});

const navigationOne = computed(() => {
    if (!navigationTree.value.map(item => item.url).includes(routeArray.value[0])) {
        return navigationTree.value?.[0]?.children.map(markSelected) ?? [];
    }

    return navigationTree.value.find(item => item.url === routeArray.value[0])?.children.map(markSelected) ?? [];
});
const customerType = computed(() => $paths.getPath('b2b') === `/${routeArray.value[0]}` ? 'B2B' : 'B2C');

const onBeforeEnter = async () => {
    await finalizePendingLocaleChange();
    usePageStore().present();
    layout.value.theme = firstBlockFullScreen.value === true ? 'clear' : 'white';
};

onMounted(async () => {
    if (layoutData?.value?.emergencyModal) modal.setNameAndOpen('emergencyModal');
    window.addEventListener('scroll', handleScroll);
    await useCartStore().loadFromLocal();
    const ignoredScrollContainers = ['station-locator', 'lenis-ignore', 'ds-select'];
    new Lenis({
        autoRaf: true,
        prevent: node => ignoredScrollContainers.some(selector => node.classList.contains(selector)),
    });
});

const markSelected = item => {
    const urlArray = item.url.split('/');
    const currentLevel = routeArray.value.slice(0, urlArray.length);

    return {
        ...item,
        active: urlArray.join('/') === currentLevel.join('/'),
    };
};

function handleScroll() {
    scrollY.value = window.scrollY;
}

const logo = computed(() => {
    if (!layoutData.logo) return { data: '/images/logo.svg', alt: 'St1' };
    const { 0: logo } = layoutData.logo;

    return logo;
});

watch(() => scrollY.value, () => {
    if (scrollY.value > scrollLimit.value && layout.value.theme === 'clear' || firstBlockFullScreen.value === false) {
        layout.value.theme = 'white';
    } else if (scrollY.value < scrollLimit.value && layout.value.theme === 'white') {
        layout.value.theme = 'clear';
    }
});

watch(() => pageData.value?.length, () => {
    layout.value.theme = firstBlockFullScreen.value === true ? 'clear' : 'white';
});

useI18n({
    useScope: 'global',
    messages: {
        'fi-FI': {
            login: {
                link: 'Kirjaudu',
            },
            additionalLinks: {
                carwash: 'Autopesulat',
            },
            navigation: {
                search: 'Search',
            },
            emergencyModal: {
                close: 'Sulje',
            },
        },
        'en-EU': {
            login: {
                link: 'Login',
            },
            additionalLinks: {
                carwash: 'Carwashes',
            },
            navigation: {
                search: 'Search',
            },
            emergencyModal: {
                close: 'Close',
            },
        },
    },
});

provide('layout', layout);
</script>
<style lang="scss">
body {
    background: var(--color-primary-white);
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

header {
    z-index: 5;
}


.page-content {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    padding-top: 10.75rem;

    .hero-banner.fullscreen:first-child {
        margin-top: -10.75rem;
    }

    @include respond-to('at most small desktop') {
        min-height: calc(100vh - 4rem);
        padding-top: 4rem;

        .hero-banner.fullscreen:first-child {
            margin-top: -4rem;
        }
    }
}

.page-container {
    width: 100%;
    overflow-x: hidden;
    min-height: calc(100vh - 4rem);

    > .notifications {
        position: fixed;
        height: calc(100vh - 20rem);
        width: 90vw;
        padding-block: 1rem;
        max-width: var(--page-max-width);
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        z-index: 6;
        pointer-events: none;

        .ds-notification {
            pointer-events: auto;
        }
    }
}

.page {
    display: grid;
    min-height: 100vh;
    max-width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(calc(100vh - 10.75rem), 1fr);

    @include respond-to('at most small desktop') {
        grid-template-rows: minmax(calc(100vh - 4rem), 1fr);
    }
}

.logo {
    display: block;

    object {
        pointer-events: none;
        width: 4.625rem;
        height: auto;
        image-rendering: optimizeQuality;
    }
}

@include respond-to('at most small desktop') {
    .navigation-container {
        grid-template-rows: 1fr 1fr 1fr auto 1fr;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;

        button.mobile {
            text-align: right;
            align-self: flex-end;
            grid-row-start: 1;
        }

        &.open {
            .quick-links-container {
                padding: 0;
                display: grid;

                .quick-links {
                    display: grid;

                    .options {
                        position: static;

                        .option {
                            font-weight: 600;
                        }

                        .option .selected {
                            background: var(--color-base-grey-100);
                        }
                    }
                }
            }
        }

        .user-container {
            border-top: 1px solid var(--color-base-grey-100);
            border-bottom: 1px solid var(--color-base-grey-100);
            padding: .75rem 0;
            margin: .75rem 0;
            width: 100%;

            .user button {
                color: var(--color-base-black);
                border: 2px solid var(--color-base-black);
            }
        }

        .search-container {
            width: 100%;
        }

        .main-nav-list-container {
            width: 100%;
            margin: 1rem 0;
        }
    }

    .bottom-quick-link:nth-of-type(2) {
        bottom: 5rem;
    }
}

@include respond-to('at least small desktop') {
    .search-container {
        display: none;
    }

    .bottom-quick-link {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        background: rgba(32, 38, 47, .85);
        color: var(--color-base-white);
        height: 4rem;
        border-radius: 4rem;
        padding: 1rem;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1rem;
        align-items: center;

        .icon-container {
            grid-column-start: 2;
            grid-row-start: 1;
        }

        span {
            grid-column-start: 1;
            grid-row-start: 1;
        }
    }

    .bottom-quick-link:nth-of-type(2) {
        bottom: 6rem;
    }
}

.submenu {
    @include respond-to('at most small desktop') {
        background-color: var(--color-base-white);
        position: absolute;
        top: 9rem;
        margin-left: 3rem;
        z-index: 2;
        width: calc(100dvw - 4rem);
        min-height: 100dvh;

        .list {
            left: -22rem;
        }

        .isSelecteds {
            right: 1rem;
        }

        .item.item {
            text-transform: capitalize;
        }
    }
}

</style>
